<template>
  <div>
    <v-btn small @click="dialogAddInvestment = true">Invertir bolsa</v-btn>

    <DialogBox :model="dialogAddInvestment" :max_width="'1000'" :useRenderWatcher="true">
      <template slot="toolbar">
        <span>Invertir bolsa</span>
      </template>
      <template slot="content">
        <v-card v-if="newInvest.projectBag" class="mb-5">
          <v-toolbar color="black" dense class="white--text" flat>Cantidades del proyecto</v-toolbar>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Moneda</th>
                  <th>Restante</th>
                  <th>Solicitado</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ favoriteCurrency }}</td>
                  <td>{{ maxAmountToInvestInFavoriteCurrency }}</td>
                  <td>{{ amountRequestedInFavoriteCurrency }}</td>
                </tr>
                <tr>
                  <td>{{ newInvest.projectBag.currency }}</td>
                  <td>
                    {{ maxAmountToInvestInProjectBagCurrency }}
                  </td>
                  <td>
                    {{ amountRequestedInProjectBagCurrency }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>

        <v-form ref="formInvestProjectBag" v-model="formInvestProjectBagValid">
          <v-select
            v-model="newInvest.projectBag"
            :items="projectBags"
            label="Bolsa"
            :item-text="
              (item) => `${item.id} - ${item.country.name} - ${item.partner.name} (fecha creación: ${formatDateL(item.created_at)})`
            "
            return-object
            @change="handleSelectProjectBag()"
          />

          <div
            v-if="
              newInvest.projectBag && (investor.investor.type === 'RETAIL' || (investor.investor.type === 'FUND' && partnerExchangeRate))
            "
          >
            <p>
              La suma de las carteras en {{ newInvest.projectBag.currency }} es: <b>{{ funds.available_funds }}</b>
            </p>
            <v-text-field
              v-model.number="newInvest.amount"
              type="number"
              label="Cantidad"
              outlined
              :suffix="suffix()"
              class="amount_field mt-1 mb-n5"
              :rules="[
                (v) => !!v || 'Campo obligatorio',
                (v) => v > 0 || 'Introduce un valor positivo',
                insufficientFundsRule,
                maxAmountToInvestRule
              ]"
            >
              <template slot="append">
                <v-btn x-small class="ml-2" @click="newInvest.amount = maxAmountToInvestInProjectBagCurrency.value">max.</v-btn>
              </template>
            </v-text-field>
          </div>
          <v-alert
            v-else-if="newInvest.projectBag && investor.investor.type === 'FUND' && !partnerExchangeRate"
            type="warning"
            color="warning"
          >
            Este inversor fondo no tiene tipo de cambio asignada para el partner al que pertenece el proyecto
          </v-alert>
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog('formInvestProjectBag')">Cerrar</v-btn>
        <v-btn text :disabled="!formInvestProjectBagValid" @click="invest()">invertir</v-btn>
      </template>
    </DialogBox>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CommonMixin from "@/mixins/CommonMixin";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import _ from "lodash";
import DialogBox from "@/components/elements/DialogBox";
import * as currency from "currency.js";

export default {
  components: {
    DialogBox
  },
  mixins: [CommonMixin, FormatDateMixin],
  props: {
    investor: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dialogAddInvestment: false,

      // Form invest project bag
      formInvestProjectBagValid: false,
      newInvest: {
        amount: 0,
        projectBag: null
      },

      // Main wallets of investor selected to invest
      wallets: [],

      // Funds available, withheld, etc...
      funds: {},
      partnerExchangeRate: null
    };
  },
  computed: {
    ...mapState({
      currencies: (state) => state.currencies.currencies,
      projectBags: (state) => state.projectBags.projectBags
    }),
    _() {
      return _;
    },
    currency() {
      return currency;
    },

    favoriteCurrency() {
      return this.investor.investor.favorite_currency;
    },

    walletAmountInFavoriteCurrency() {
      const available_funds = currency(this.wallets.find((e) => e.currency === this.favoriteCurrency)?.available_funds ?? 0, {
        fromCents: true
      });
      return available_funds;
    },

    walletAmountInProjectBagCurrency() {
      const available_funds = currency(this.wallets.find((e) => e.currency === this.newInvest.projectBag.currency)?.available_funds ?? 0);
      return available_funds;
    },

    favoriteCurrencyToProjectBagCurrency() {
      let amountConverted = 0;

      if (this.investor.investor.tpye === "FUND") {
        amountConverted = this.convertAmount(
          currency(this.wallets.find((e) => e.currency === this.favoriteCurrency)?.available_funds) ?? 0,
          this.partnerExchangeRate
        );
      } else {
        amountConverted = this.convertCurrency(
          currency(this.wallets.find((e) => e.currency === this.favoriteCurrency)?.available_funds) ?? 0,
          this.favoriteCurrency,
          this.newInvest.projectBag.currency
        );
      }

      return amountConverted ?? 0;
    },

    maxAmountToInvestInFavoriteCurrency() {
      const amount_invested_remaining = currency(this.newInvest.projectBag.amount_invested_remaining, { fromCents: true });
      let amount = 0;

      if (this.investor.investor.tpye === "FUND") {
        amount = this.convertAmount(currency(amount_invested_remaining), this.partnerExchangeRate);
      } else {
        amount = this.convertCurrency(currency(amount_invested_remaining), this.newInvest.projectBag.currency, this.favoriteCurrency);
      }

      return amount;
    },

    maxAmountToInvestInProjectBagCurrency() {
      return currency(this.newInvest.projectBag.amount_invested_remaining, { fromCents: true });
    },

    amountRequestedInFavoriteCurrency() {
      const amount_requested = currency(this.newInvest.projectBag.amount, { fromCents: true });
      let amount = 0;

      if (this.investor.investor.tpye === "FUND") {
        amount = this.convertAmount(currency(amount_requested), this.partnerExchangeRate);
      } else {
        amount = this.convertCurrency(currency(amount_requested), this.newInvest.projectBag.currency, this.favoriteCurrency);
      }

      return amount;
    },

    amountRequestedInProjectBagCurrency() {
      return currency(this.newInvest.projectBag.amount, { fromCents: true });
    },

    // Control if there are enough funds to invest
    insufficientFundsRule() {
      return (v) => v <= this.funds.available_funds || "Saldo insuficiente";
    },

    // Control max amount available
    maxAmountToInvestRule() {
      return (v) => v <= this.maxAmountToInvestInProjectBagCurrency || "Máximo de inversión superado";
    }
  },
  async created() {
    await this.$store.dispatch("investments/getInvestmentsSettings");
    await this.$store.dispatch("currencies/getCurrencies");
    await this.$store.dispatch("projectBags/getProjectBags", { filter: { status: "INVESTABLE" } });
  },
  methods: {
    // Invest project
    async invest() {
      if (this.$refs.formInvestProjectBag.validate()) {
        await this.$store.dispatch("projectBags/investProjectBag", {
          id: this.newInvest.projectBag.id,
          investor_id: this.investor.id,
          amount:
            this.newInvest.amount > this.maxAmountToInvestInProjectBagCurrency.value
              ? this.maxAmountToInvestInProjectBagCurrency.intValue
              : this.convertToInt(this.newInvest.amount)
        });

        await this.$store.dispatch("projectBags/getProjectBags", { filter: { status: "INVESTABLE" } });
        this.closeDialog("formInvestProjectBag");
      }
    },

    // Get the symbol of a currency
    currencySymbol(currency) {
      return this.currencies.find((e) => e.id === currency)?.symbol;
    },

    suffix() {
      let favoriteCurrencyAmount = 0;

      if (this.investor.investor.tpye === "FUND") {
        favoriteCurrencyAmount = this.convertAmount(currency(this.newInvest.amount), this.partnerExchangeRate);
      } else {
        favoriteCurrencyAmount = this.convertCurrency(
          currency(this.newInvest.amount),
          this.newInvest.projectBag.currency,
          this.favoriteCurrency
        );
      }

      return `${this.currencySymbol(this.newInvest.projectBag.currency)} / ${favoriteCurrencyAmount} ${this.currencySymbol(
        this.favoriteCurrency
      )}`;
    },

    async getWallets() {
      const wallets = await this.$store.dispatch("investors/getInvestorWallets", this.investor.id);
      this.wallets = wallets.filter((e) => e.main_wallet === true);
    },

    // Get the funds of the wallets in the currency of the project
    async getFundsInProjectBagCurrency() {
      let getTotalFundsData = {};

      if (this.investor.investor.type === "RETAIL") {
        getTotalFundsData = { investor_id: this.investor.id, currency: this.newInvest.projectBag.currency };
      } else {
        getTotalFundsData = {
          investor_id: this.investor.id,
          currency: this.newInvest.projectBag.currency,
          main_wallet_exchange_rate: this.partnerExchangeRate
        };
      }

      const funds = await this.$store.dispatch("investors/getTotalFunds", getTotalFundsData);

      for (const key of this._.keys(funds)) {
        funds[key] = currency(funds[key], { fromCents: true });
      }

      this.funds = funds;
    },

    // Get the exchange rate of a partner assigned to a fund investor
    getPartnerExchangeRate(partnerId) {
      console.log(partnerId);
      this.partnerExchangeRate = this.investor.investor.exchange_rates[partnerId];
    },

    async handleSelectProjectBag() {
      await this.getWallets();
      if (this.investor.investor.type === "FUND") this.getPartnerExchangeRate(this.newInvest.projectBag.partner.id);
      if (this.investor.investor.type === "RETAIL" || (this.investor.investor.type === "FUND" && this.partnerExchangeRate)) {
        await this.getFundsInProjectBagCurrency(this.newInvest.projectBag.id);
      }
    },

    // Close dialogs, clear and reset validation form fields
    closeDialog(formRef) {
      this.$refs[formRef].resetValidation();
      Object.assign(this.$data, this.$options.data());
    }
  }
};
</script>
