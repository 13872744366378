<template>
  <div>
    <v-text-field v-model="investorEditable.first_name" label="Nombre" :readonly="!editable" :rules="formRules.textRules" />
    <v-text-field
      v-model="investorEditable.identity_document"
      label="Documento de indentidad"
      :readonly="!editable"
      :rules="formRules.textRules"
    />
    <CountryAutocomplete
      :listener="$store.state.investors.investor"
      :countryId="investorEditable.country"
      :editable="editable"
      @selected="investorEditable.country = $event"
    />
    <v-select
      v-model="investorEditable.investor.commission"
      :items="commissions"
      item-value="key"
      item-text="value.name"
      label="Comisión"
      :readonly="!editable"
      :rules="[(v) => !_.isEmpty(v) || 'Campo obligatorio']"
    />
    <v-select
      v-model="investorEditable.investor.favorite_currency"
      :items="currencies.filter((e) => e.investable === true)"
      item-text="name"
      item-value="id"
      label="Moneda preferida"
      :readonly="!editable"
      :rules="[(v) => !!v || 'Campo obligatorio']"
    />
    <div class="d-flex align-center">
      <h4 class="my-4">Tipos de cambio:</h4>
      <v-btn
        v-if="arrayExchangeRates.length !== partners.length && editable && permissions.editExchangeRate.includes(user.role)"
        elevation="2"
        x-small
        class="ml-3"
        icon
        @click="arrayExchangeRates.push({ partner: {}, value: 0 })"
      >
        <v-icon>add</v-icon>
      </v-btn>
    </div>
    <v-row>
      <v-col v-for="(exchangeRate, i) in arrayExchangeRates" :key="i" cols="12" md="4">
        <div class="secondary pa-4 rounded-lg">
          <div v-if="editable && permissions.editExchangeRate.includes(user.role)" class="text-right">
            <v-btn icon x-small color="red" @click="arrayExchangeRates.splice(i, 1)">
              <v-icon>delete</v-icon>
            </v-btn>
          </div>
          <v-row>
            <v-col cols="8">
              <v-select
                v-model="exchangeRate.partner"
                :items="partners"
                :item-disabled="isPartnerSelected"
                item-text="name"
                label="Partner"
                return-object
                :readonly="!editable || !permissions.editExchangeRate.includes(user.role)"
                :rules="[(v) => !_.isEmpty(v) || 'Campo obligatorio']"
              >
                <template slot="selection" slot-scope="data"> {{ data.item.name }} </template>
              </v-select>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model.number="exchangeRate.value"
                type="number"
                label="Tipo de cambio"
                :readonly="!editable || !permissions.editExchangeRate.includes(user.role)"
                :rules="formRules.numberRules"
              />
            </v-col>
            <v-col
              v-if="investorEditable.investor.exchange_rates[exchangeRate.partner.id] && controlExchangeRate(exchangeRate)"
              cols="12"
              class="pt-0"
            >
              <p class="d-inline-flex">
                <v-icon color="warning" class="mr-2">info</v-icon> La variación con el tipo de cambio del día es mayor al 10% (TC del día
                {{ $store.state.currencies.rates[exchangeRate.partner.country.currency] }})
              </p>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import CountryAutocomplete from "@/components/elements/locations/CountryAutocomplete.vue";

export default {
  components: { CountryAutocomplete },
  name: "FundProfileData",
  mixins: [FormRulesMixin, FormatDateMixin],
  props: {
    investorEditable: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      permissions: {
        editExchangeRate: ["SUPERADMIN"]
      },
      formEditInvestorValid: false,
      arrayExchangeRates: []
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      partners: (state) => state.partners.partners,
      commissions: (state) => state.investments.commissions,
      currencies: (state) => state.currencies.currencies
    }),
    _() {
      return _;
    }
  },
  watch: {
    "investorEditable.investor.exchange_rates"() {
      this.createArrayExchangeRates();
    }
  },
  async created() {
    await this.$store.dispatch("partners/getPartners");
    this.createArrayExchangeRates();
  },
  methods: {
    createArrayExchangeRates() {
      const arr = [];
      for (const exchangeRateKey of this._.keys(this.investorEditable.investor?.exchange_rates)) {
        arr.push({
          partner: this.partners.find((e) => e.id === exchangeRateKey),
          value: this.investorEditable.investor?.exchange_rates[exchangeRateKey]
        });
      }
      this.arrayExchangeRates = arr;
    },

    // Return if some partner is already selected in exchange rates
    isPartnerSelected(item) {
      return this.arrayExchangeRates.some((e) => e.partner.id === item.id);
    },

    // check that the exchange rate does not exceed the percentage
    controlExchangeRate(customExchangeRate) {
      const exchangeRateOfTheDay = this.$store.state.currencies.rates[customExchangeRate.partner.country.currency];
      const percentage = 10;
      const maxExchangeRate = (exchangeRateOfTheDay * (100 + percentage)) / 100;
      const minExchangeRate = (exchangeRateOfTheDay * (100 - percentage)) / 100;

      return customExchangeRate.value <= minExchangeRate || customExchangeRate.value >= maxExchangeRate;
    }
  }
};
</script>
